import { render, staticRenderFns } from "./patentQuickenExplain.vue?vue&type=template&id=01895af6&scoped=true&"
import script from "./patentQuickenExplain.vue?vue&type=script&lang=js&"
export * from "./patentQuickenExplain.vue?vue&type=script&lang=js&"
import style0 from "./patentQuickenExplain.vue?vue&type=style&index=0&id=01895af6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01895af6",
  null
  
)

export default component.exports